import React, { useEffect, useState, useRef } from "react";
import EmailContactForm from "../../components/emailContactForm/emailContactForm";
import Header from "../../components/header/header";
import leftPattern from "../../assets/img/left-side-pattern.png";
import rightPattern from "../../assets/img/right-side-pattern.png";
import MobileMenu from "../../components/mobile-menu/mobile-menu";
import Footer from "../../components/footer/footer";
import Section1Hero from "./section-1-hero";
import Section2About from "./section-2-about";
import Section3Resources from "./section-3-resources";
import Section4Live from "./section-4-live";
import Section6Tour from "./section-6-tour";
import Section7Contact from "./section-7-contact";

function LandingPage() {
  const homeSectionRef = useRef(null);
  const aboutSectionRef = useRef(null);
  const resourcesSectionRef = useRef(null);
  const liveSectionRef = useRef(null);
  const tourSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [emailContactFormOpen, setEmailContactFormOpen] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    document.title = "Ategbe Movement • Home";
    window.scrollTo({ top: 0, behavior: "auto" });

    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${leftPattern})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "5%",
        backgroundPosition: "left 6%",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${rightPattern})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "5%",
          backgroundPosition: "right ",
        }}
      >
        {mobileMenuOpen && (
          <MobileMenu
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
            handleHomeSectionRef={homeSectionRef}
            handleAboutSectionRef={aboutSectionRef}
            handleResourcesSectionRef={resourcesSectionRef}
            handleLiveSectionRef={liveSectionRef}
            handleTourSectionRef={tourSectionRef}
            handleContactSectionRef={contactSectionRef}
          />
        )}
        {emailContactFormOpen && (
          <EmailContactForm setEmailContactFormOpen={setEmailContactFormOpen} />
        )}
        <Header
          setMobileMenuOpen={setMobileMenuOpen}
          handleHomeSectionRef={homeSectionRef}
          handleAboutSectionRef={aboutSectionRef}
          handleResourcesSectionRef={resourcesSectionRef}
          handleLiveSectionRef={liveSectionRef}
          handleTourSectionRef={tourSectionRef}
          handleContactSectionRef={contactSectionRef}
        />
        <section ref={homeSectionRef}>
          <Section1Hero setEmailContactFormOpen={setEmailContactFormOpen} />
        </section>
        <section ref={aboutSectionRef}>
          <Section2About />
        </section>
        <section ref={resourcesSectionRef}>
          <Section3Resources />
        </section>
        <section ref={liveSectionRef}>
          <Section4Live />
        </section>
        <section ref={tourSectionRef}>
          <Section6Tour />
        </section>
        <section ref={contactSectionRef}>
          <Section7Contact />
        </section>
        <section>
          <Footer
            handleHomeSectionRef={homeSectionRef}
            handleAboutSectionRef={aboutSectionRef}
            handleResourcesSectionRef={resourcesSectionRef}
            handleContactSectionRef={contactSectionRef}
          />
        </section>
      </div>
    </div>
  );
}

export default LandingPage;
