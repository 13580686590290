import React, { useContext } from "react";
import Buttons from "../../components/buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";
import { ReactComponent as TiktokIcon } from "../../assets/svg/landing-page/tiktok-icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/landing-page/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svg/landing-page/instagram-icon.svg";

function Section4Live() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);
  const videosUrls = [
    {
      id: 1,
      url: "https://www.youtube.com/embed/ix5ZgxXtuac?si=HMdVKJqEqZl2dDmG",
    },
    {
      id: 2,
      url: "https://www.youtube.com/embed/Hf1dvIUFz3I?si=6bZdjJpYcksyd4dD",
    },
    {
      id: 3,
      url: "https://www.youtube.com/embed/lOSwYJeuIXc?si=2cOQWoXHrZnHhxSr",
    },
  ];

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="px-[150px] pb-[20px] w-full">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese"
                  ? "Participe ao vivo"
                  : "Join Live"
              }
              buttonDotSymbolStyling="text-[100px] text-[#FF0000]"
              specificButtonTextStyling="cursor-default text-center mb-[50px] text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>
          <div className="flex items-center justify-between ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@ategbemovement8?_t=8dIoKiuhpIs&_r=1"
            >
              <Buttons
                specificButtonTitle="Tiktok"
                specificButtonIcon={<TiktokIcon className="w-[150px]" />}
                specificButtonStyling="w-[250px] flex items-center justify-center bg-white rounded-full ml-10 px-8 rounded-full border-4 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
              />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/Ategbe27"
            >
              <Buttons
                specificButtonTitle="Facebook"
                specificButtonIcon={<FacebookIcon className="w-[120px]" />}
                specificButtonStyling="w-[250px] flex items-center justify-center bg-white rounded-full px-8 rounded-full border-4 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
              />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/ategbemovement"
            >
              <Buttons
                specificButtonTitle="Instagram"
                specificButtonIcon={<InstagramIcon className="w-[150px]" />}
                specificButtonStyling="w-[250px] flex items-center justify-center bg-white rounded-full mr-10 px-8 pt-2 rounded-full border-4 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
              />
            </a>
          </div>

          <div className="flex items-center justify-center mt-20">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese" ? "vídeos" : "videos"
              }
              specificButtonTextStyling="cursor-default text-center mb-[50px] text-[70px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>

          <div className="flex justify-between">
            {videosUrls.map((video) => (
              <div key={video.id}>
                <iframe
                  className="rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                  // width="960"
                  height="515"
                  src={video.url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[6px] flex items-center justify-center">
        <div className="px-[20px] pt-[40px] pb-[40px] w-10/12">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese"
                  ? "Participe ao vivo"
                  : "Join Live"
              }
              buttonDotSymbolStyling="text-[50px] text-[#FF0000]"
              specificButtonTextStyling="cursor-default text-center mb-[35px] text-[56px] leading-[48px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>
          <div className="flex items-center justify-center">
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@ategbemovement8?_t=8dIoKiuhpIs&_r=1"
              >
                <Buttons
                  specificButtonTitle="Tiktok"
                  specificButtonIcon={<TiktokIcon className="w-[100px]" />}
                  specificButtonStyling="w-[200px] h-[60px] flex items-center justify-center bg-white rounded-full mb-4 px-6 rounded-full border-2 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Ategbe27"
              >
                <Buttons
                  specificButtonTitle="Facebook"
                  specificButtonIcon={<FacebookIcon className="w-[100px]" />}
                  specificButtonStyling="w-[200px] h-[60px] flex items-center justify-center bg-white rounded-full mb-4 px-6 rounded-full border-2 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://instagram.com/ategbemovement"
              >
                <Buttons
                  specificButtonTitle="Instagram"
                  specificButtonIcon={<InstagramIcon className="w-[100px]" />}
                  specificButtonStyling="w-[200px] h-[60px] flex items-center justify-center bg-white rounded-full mb-4 px-6 pt-2 rounded-full border-2 border-ategbe-green hover:border-[#FF0000]
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                />
              </a>
            </div>
          </div>
          <div className="w-full h-full my-[6px] flex items-center justify-center">
            <div className="px-[20px] pt-[40px] pb-[40px] w-10/12">
              <div className="flex items-center justify-center">
                <Buttons
                  specificButtonText={
                    currentLanguage === "portuguese" ? "vídeos" : "videos"
                  }
                  specificButtonTextStyling="cursor-default text-center mb-[35px] text-[56px] leading-[48px] text-ategbe-font-white font-AfricaVibesRegular"
                />
              </div>

              <div className="flex justify-center">
                <div>
                  {videosUrls.map((video) => (
                    <div className="flex flex-col" key={video.id}>
                      <iframe
                        className="rounded-xl my-6"
                        width="300"
                        height="515"
                        src={video.url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section4Live;
