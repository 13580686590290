import React, { useContext } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import AtegbeLogo from "../../assets/img/ategbe-icon.png";

function Footer({
  handleHomeSectionRef,
  handleAboutSectionRef,
  handleResourcesSectionRef,
  handleContactSectionRef,
}) {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** handles Home Section Ref */
  const handlesHomeSectionRef = () => {
    const barHeight = "200px";
    // eslint-disable-next-line no-param-reassign
    handleHomeSectionRef.current.style.scrollMargin = barHeight;
    handleHomeSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles About Section Ref */
  const handlesAboutSectionRef = (barHeight) => {
    // eslint-disable-next-line no-param-reassign
    handleAboutSectionRef.current.style.scrollMargin = barHeight;
    handleAboutSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Resources Section Ref */
  const handlesResourcesSectionRef = (barHeight) => {
    // eslint-disable-next-line no-param-reassign
    handleResourcesSectionRef.current.style.scrollMargin = barHeight;
    handleResourcesSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Contact Section Ref */
  const handlesContactSectionRef = (barHeight) => {
    // eslint-disable-next-line no-param-reassign
    handleContactSectionRef.current.style.scrollMargin = barHeight;
    handleContactSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="mt-[50px] cursor-default">
        <div className="flex items-center justify-center">
          <div className="w-full flex justify-evenly">
            <div className="w-[326px]">
              <img
                alt="Ategbe"
                title="Ategbe"
                src={AtegbeLogo}
                className="h-[120px] mb-4"
              />
              {currentLanguage === "portuguese" ? (
                <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                  Ategbe é uma empresa de mídia que ultrapassa os limites do
                  Yorùbá Cultura através de recursos, roupas, mercadorias e
                  também filmando ativamente os eventos culturais e tradicionais
                  iorubás de classe mundial documentários..
                </p>
              ) : (
                <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                  Ategbe is a Media Company that pushes the boundaries of Yorùbá
                  Culture through resources, clothing, merchandise and also
                  actively filming world-class Yoruba cultural and traditional
                  documentaries.
                </p>
              )}
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese"
                  ? "Links Rápidos"
                  : "Quick links"}
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={currentLanguage === "portuguese" ? "Lar" : "Home"}
                      onClick={() => handlesHomeSectionRef()}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese" ? "Lar" : "Home"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Sobre nós"
                          : "About Us"
                      }
                      onClick={() => handlesAboutSectionRef("250px")}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Sobre nós"
                          : "About Us"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Recursos"
                          : "Resources"
                      }
                      onClick={() => handlesResourcesSectionRef("250px")}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Recursos"
                          : "Resources"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Contacto"
                          : "Contact Us"
                      }
                      onClick={() => handlesContactSectionRef("250px")}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Contacto"
                          : "Contact Us"}
                      </p>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese" ? "Apoiar" : "Support"}
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/2348099511379?text=Hello%20Ategebe,%20I%20am%20contacting%20you%20from%20the%20website,%20I%20need%20some%20support%20regarding%20your%20product%20or%20services."
                    >
                      <button type="button" title="WhatsApp">
                        <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                          WhatsApp
                        </p>
                      </button>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/ategbemovement"
                    >
                      <button type="button" title="Instagram">
                        <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                          Instagram
                        </p>
                      </button>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/Ategbe27"
                    >
                      <button type="button" title="Facebook">
                        <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                          Facebook
                        </p>
                      </button>
                    </a>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese"
                  ? "Horário de funcionamento"
                  : "Opening Hours"}
              </p>
              <nav>
                <ol>
                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Segunda-feira - Quinta-feira"
                        : "Monday - Thursday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      9AM - 8PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Sexta-feira - Sábado"
                        : "Friday - Saturday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      9AM - 9PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Domingo" : "Sunday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      12PM - 8PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Feriados públicos"
                        : "Public Holidays"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Abrir" : "Open"}
                    </li>
                  </div>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="h-[1px] mt-[50px] mx-[110px] bg-[#3C6791] opacity-50" />
        <div className="h-[73px] flex items-center justify-center">
          <div>
            {currentLanguage === "portuguese" ? (
              <p className="mb-4 mt-6 font-FaktumBold text-[16px] leading-[24px] text-ategbe-green text-center ">
                © Direitos autorais 2023. Marcas registradas e marcas são
                propriedade da Ategbe Media.
              </p>
            ) : (
              <p className="mb-4 mt-6 font-FaktumBold text-[16px] leading-[24px] text-ategbe-green text-center ">
                © Copyright 2023. Trademarks and Brands are the Property of
                Ategbe Media.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="mt-[50px] cursor-default">
        <div className="w-full px-[50px]">
          <div className="">
            <img
              alt="Ategbe"
              title="Ategbe"
              src={AtegbeLogo}
              className="h-[120px] mb-4"
            />
            {currentLanguage === "portuguese" ? (
              <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                Ategbe é uma empresa de mídia que ultrapassa os limites do
                Yorùbá Cultura através de recursos, roupas, mercadorias e também
                filmando ativamente os eventos culturais e tradicionais iorubás
                de classe mundial documentários..
              </p>
            ) : (
              <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                Ategbe is a Media Company that pushes the boundaries of Yorùbá
                Culture through resources, clothing, merchandise and also
                actively filming world-class Yoruba cultural and traditional
                documentaries.
              </p>
            )}
          </div>

          <div>
            <p className="mb-3 mt-10 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese"
                ? "Links Rápidos"
                : "Quick links"}
            </p>
            <nav>
              <ol>
                <li className="mb-4">
                  <button
                    type="button"
                    title={currentLanguage === "portuguese" ? "Lar" : "Home"}
                    onClick={() => handlesHomeSectionRef()}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Lar" : "Home"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Sobre nós"
                        : "About Us"
                    }
                    onClick={() => handlesAboutSectionRef("160px")}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Sobre nós"
                        : "About Us"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Recursos"
                        : "Resources"
                    }
                    onClick={() => handlesResourcesSectionRef("120px")}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Recursos"
                        : "Resources"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Contacto"
                        : "Contact Us"
                    }
                    onClick={() => handlesContactSectionRef("120px")}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Contacto"
                        : "Contact Us"}
                    </p>
                  </button>
                </li>
              </ol>
            </nav>
          </div>

          <div>
            <p className="mb-3 mt-5 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese" ? "Apoiar" : "Support"}
            </p>
            <nav>
              <ol>
                <li className="mb-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/2348099511379?text=Hello%20Ategebe,%20I%20am%20contacting%20you%20from%20the%20website,%20I%20need%20some%20support%20regarding%20your%20product%20or%20services."
                  >
                    <button type="button" title="WhatsApp">
                      <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        WhatsApp
                      </p>
                    </button>
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/ategbemovement"
                  >
                    <button type="button" title="Instagram">
                      <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        Instagram
                      </p>
                    </button>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Ategbe27"
                  >
                    <button type="button" title="Facebook">
                      <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        Facebook
                      </p>
                    </button>
                  </a>
                </li>
              </ol>
            </nav>
          </div>

          <div>
            <p className="mb-3 mt-5 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese"
                ? "Horário de funcionamento"
                : "Opening Hours"}
            </p>
            <nav>
              <ol>
                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Segunda-feira - Quinta-feira"
                      : "Monday - Thursday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    9AM - 8PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Sexta-feira - Sábado"
                      : "Friday - Saturday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    9AM - 9PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese" ? "Domingo" : "Sunday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    12PM - 8PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Feriados públicos"
                      : "Public Holidays"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese" ? "Abrir" : "Open"}
                  </li>
                </div>
              </ol>
            </nav>
          </div>
        </div>
        <div className="h-[1px] mt-[50px] mx-[110px] bg-[#3C6791] opacity-50" />
        <div className="h-[73px] flex items-center justify-center px-7">
          <div>
            {currentLanguage === "portuguese" ? (
              <p className="mb-4 mt-6 font-FaktumBold text-[12px] leading-[14px] text-ategbe-green text-center ">
                © Direitos autorais 2023. Marcas registradas e marcas são
                propriedade da Ategbe Media.
              </p>
            ) : (
              <p className="mb-4 mt-6 font-FaktumBold text-[12px] leading-[14px text-ategbe-green text-center ">
                © Copyright 2023. Trademarks and Brands are the Property of
                Ategbe Media.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1188 ? <>{desktop()}</> : <>{mobile()}</>}</div>;
}

export default Footer;
