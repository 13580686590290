import React, { useContext } from "react";
import Buttons from "../../components/buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";
import drumImage from "../../assets/img/drum-image.png";
import handsImage from "../../assets/img/hands-image.png";
import potImage from "../../assets/img/pot-image.png";
import bagImage from "../../assets/img/bag-image.png";

function Section6Tour() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="px-[150px] pb-[20px] w-full">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese" ? "percorrer" : "tour"
              }
              specificButtonTextStyling="cursor-default text-center mb-[50px] text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>

          <div
            className="flex items-center justify-center
          bg-ategbe-brown w-full h-[1020px] p-[50px]
           "
          >
            <div>
              <div className="w-full flex items-center justify-end">
                <img
                  src={drumImage}
                  alt="Drum"
                  className="w-[100%] h-[60%] mb-[20px]"
                />
              </div>

              <div className="w-[100%] h-[240px] flex">
                <div>
                  <img
                    src={handsImage}
                    alt="Hands"
                    className="w-[95%] h-[100%]"
                  />
                </div>
                <div>
                  <img src={potImage} alt="Pot" className="w-[95%] h-[100%]" />
                </div>
                <div>
                  <img src={bagImage} alt="Bag" className=" h-[100%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[6px] flex items-center justify-center">
        <div className="px-[20px] pt-[40px] pb-[40px] w-10/12">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese" ? "percorrer" : "tour"
              }
              specificButtonTextStyling="cursor-default text-center mb-[35px] text-[56px] leading-[48px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>

          <div className="flex flex-col justify-center items-center bg-ategbe-brown w-full p-[15px]">
            <img
              src={drumImage}
              alt="Drum"
              className="w-[100%] h-[100%] mb-[10px]"
            />
            <img src={handsImage} alt="Hands" className="w-[100%] h-[100%]" />
            <img
              src={potImage}
              alt="Pot"
              className="w-[100%] h-[100%] mt-[10px]"
            />
            <img
              src={bagImage}
              alt="Bag"
              className="w-[100%] h-[100%] mt-[10px]"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section6Tour;
