import React, { useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as CancelIcon } from "../../assets/svg/header/cancel-icon.svg";
import { LanguageContext } from "../../utils/context";
import Buttons from "../buttons/buttons";

function MobileMenu({
  mobileMenuOpen,
  setMobileMenuOpen,
  handleHomeSectionRef,
  handleAboutSectionRef,
  handleResourcesSectionRef,
  handleLiveSectionRef,
  handleTourSectionRef,
  handleContactSectionRef,
}) {
  const { currentLanguage } = useContext(LanguageContext);
  const { width } = useWindowResize();

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [mobileMenuOpen]);

  /** handles Home Section Ref */
  const handlesHomeSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "100px";
    // eslint-disable-next-line no-param-reassign
    handleHomeSectionRef.current.style.scrollMargin = barHeight;
    handleHomeSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles About Section Ref */
  const handlesAboutSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "160px";
    // eslint-disable-next-line no-param-reassign
    handleAboutSectionRef.current.style.scrollMargin = barHeight;
    handleAboutSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Resources Section Ref */
  const handlesResourcesSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "120px";
    // eslint-disable-next-line no-param-reassign
    handleResourcesSectionRef.current.style.scrollMargin = barHeight;
    handleResourcesSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Live Section Ref */
  const handlesLiveSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "120px";
    // eslint-disable-next-line no-param-reassign
    handleLiveSectionRef.current.style.scrollMargin = barHeight;
    handleLiveSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Tour Section Ref */
  const handlesTourSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "120px";
    // eslint-disable-next-line no-param-reassign
    handleTourSectionRef.current.style.scrollMargin = barHeight;
    handleTourSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles Contact Section Ref */
  const handlesContactSectionRef = () => {
    setMobileMenuOpen(false);
    const barHeight = "120px";
    // eslint-disable-next-line no-param-reassign
    handleContactSectionRef.current.style.scrollMargin = barHeight;
    handleContactSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // lets start animation
  const item = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 1,
      },
    },
  };

  return (
    <div>
      {width < 1060 && (
        <AnimatePresence>
          {mobileMenuOpen && (
            <div className="sticky z-50">
              <div className="fixed bg-ategbe-background-black w-full">
                <motion.div
                  variants={item}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "100vh", opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  exit="exit"
                >
                  <motion.a
                    initial={{ y: 80, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.7 }}
                    exit={{
                      opacity: 0,
                      y: 90,
                      transition: {
                        ease: "easeInOut",
                        delay: 0.1,
                      },
                    }}
                  >
                    <div className="w-full flex justify-end px-4 py-4">
                      <Buttons
                        specificButtonTitle="Close"
                        specificButtonIcon={<CancelIcon />}
                        specificButtonClick={() => setMobileMenuOpen(false)}
                      />
                    </div>
                  </motion.a>
                  <ul className="text-center flex justify-center">
                    <div>
                      <li className="mt-20 mb-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.6 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.4,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese" ? "Lar" : "Home"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese" ? "Lar" : "Home"
                            }
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() => handlesHomeSectionRef()}
                          />
                        </motion.a>
                      </li>

                      <li className="my-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.5 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.3,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese"
                                ? "Sobre"
                                : "About"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese"
                                ? "Sobre"
                                : "About"
                            }
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() => handlesAboutSectionRef()}
                          />
                        </motion.a>
                      </li>

                      <li className="my-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.4 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.2,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese"
                                ? "Recursos"
                                : "Resources"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese"
                                ? "Recursos"
                                : "Resources"
                            }
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() =>
                              handlesResourcesSectionRef()
                            }
                          />
                        </motion.a>
                      </li>

                      <li className="my-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.3 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.1,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese"
                                ? "Participe ao vivo"
                                : "Join Live"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese"
                                ? "Participe ao vivo"
                                : "Join Live"
                            }
                            buttonDotSymbolStyling="text-xl text-[#FF0000]"
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() => handlesLiveSectionRef()}
                          />
                        </motion.a>
                      </li>

                      <li className="my-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.2,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese"
                                ? "Percorrer"
                                : "Tour"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese"
                                ? "Percorrer"
                                : "Tour"
                            }
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() => handlesTourSectionRef()}
                          />
                        </motion.a>
                      </li>

                      <li className="my-10">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.1 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.2,
                            },
                          }}
                        >
                          <Buttons
                            specificButtonTitle={
                              currentLanguage === "portuguese"
                                ? "Contacto"
                                : "Contact"
                            }
                            specificButtonText={
                              currentLanguage === "portuguese"
                                ? "Contacto"
                                : "Contact"
                            }
                            specificButtonTextStyling="font-FaktumRegular text-2xl text-ategbe-green lg:whitespace-nowrap"
                            specificButtonClick={() =>
                              handlesContactSectionRef()
                            }
                          />
                        </motion.a>
                      </li>
                    </div>
                  </ul>
                </motion.div>
              </div>
            </div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}

export default MobileMenu;
