/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as CancelIcon } from "../../assets/svg/general/cancel-icon.svg";
import { ReactComponent as LoadingIcon } from "../../assets/svg/general/loading-icon.svg";
import Inputs from "../inputs/inputs";
import Buttons from "../buttons/buttons";

function EmailContactForm({ setEmailContactFormOpen }) {
  const { width } = useWindowResize();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [warning, setWarning] = useState(false);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const handleContactForm = () => {
    if (!name || !email || !phoneNumber || !message) {
      setWarning(true);
    } else {
      setBtnIsLoading(true);
      window.location = `mailto:AtegbeMovement@gmail.com?subject=From Ategbe Website: My name is ${name}&body=My phone number is ${phoneNumber}, My email address is ${email}, and I would like to talk about the following: ${message}`;
      setTimeout(() => {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
      }, 5000);
      setTimeout(() => {
        setEmailContactFormOpen(false);
      }, 5000);
    }
    setTimeout(() => {
      setWarning(false);
    }, 5000);
  };

  /** displays Warning */
  const displayWarningDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="-mt-4 mb-4 px-4 py-2 w-full rounded font-FaktumRegular text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to send a contact email...
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayWarningMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-FaktumRegular text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to send a contact email...
        </p>
      </div>
    );
  };

  return (
    <div>
      {width > 1060 ? (
        <div>
          <div className="fixed z-50 outline-none focus:outline-none">
            <div className="flex items-center justify-center w-screen h-screen">
              <div className="relative w-[480px] max-h-[90%] pb-[40px] pt-[20px] px-[30px] rounded-lg bg-white overflow-auto  outline-none focus:outline-none">
                <div className="flex items-center justify-end p-[5px]">
                  <button
                    type="button"
                    title="Close"
                    className="cursor-pointer"
                    onClick={() => setEmailContactFormOpen(false)}
                  >
                    <CancelIcon />
                  </button>
                </div>
                <div className="flex items-center justify-center ">
                  <div>
                    <p className="text-ategbe-green w-[70%] -mt-[40px] mb-2 font-FaktumBold text-[32px] leading-[44.8px]">
                      Contact Ategbe
                    </p>
                    <p className="text-ategbe-font-black w-[70%] mb-1 font-FaktumRegular text-[16px] leading-[26px] opacity-70">
                      To get started, fill out the form below:
                    </p>
                    <div>
                      <div className="mt-[20px] flex items-center justify-center">
                        <form>
                          <div>
                            <Inputs
                              specificInputLabel="fullName"
                              specificInputTitle="Full Name"
                              specificInputType="text"
                              specificInputPlaceholder="E.g John Doe"
                              specificInputValue={name}
                              specificInputOnChange={(e) =>
                                setName(e.target.value)
                              }
                              specificInputStyling="mb-[24px] rounded-[4px] w-[442px] h-[48px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                            />
                            <Inputs
                              specificInputLabel="emailAddress"
                              specificInputTitle="Email address"
                              specificInputType="text"
                              specificInputPlaceholder="E.g johndoe@contact.com"
                              specificInputValue={email}
                              specificInputOnChange={(e) =>
                                setEmail(e.target.value)
                              }
                              specificInputStyling="mb-[24px] rounded-[4px] w-[442px] h-[48px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                            />
                            <Inputs
                              specificInputLabel="mobileNumber"
                              specificInputTitle="Mobile number"
                              specificInputType="text"
                              specificInputPlaceholder="E.g +08100000000"
                              specificInputValue={phoneNumber}
                              specificInputOnChange={(e) =>
                                setPhoneNumber(e.target.value)
                              }
                              specificInputStyling="mb-[24px] rounded-[4px] w-[442px] h-[48px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                            />
                            <div>
                              <label htmlFor="message">
                                <p className="mb-1 font-FaktumBold text-[13px] leading-[19.5px] text-ategbe-font-black">
                                  Message
                                </p>
                                <textarea
                                  id="message"
                                  type="text"
                                  placeholder="Enter your message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  className=" text-[14px] leading-[22px] py-3 px-5 appearance-none focus:outline-none mb-[24px] rounded-[4px] w-[442px] h-[124px] max-h-[150px] bg-white text-[#7d7e83] placeholder-[#B9B9B9] border border-[#BBBBBB]"
                                />
                              </label>
                            </div>
                            {warning ? displayWarningDesktop() : <span />}
                            <div className="flex items-center justify-center">
                              {btnIsLoading ? (
                                <Buttons
                                  specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                                  specificButtonTitle="Loading"
                                  specificButtonIcon={
                                    <LoadingIcon className="spinning" />
                                  }
                                />
                              ) : (
                                <Buttons
                                  specificButtonType
                                  specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                                  specificButtonTitle="Send to Ategbe"
                                  specificButtonText="Send to Ategbe"
                                  specificButtonClick={() =>
                                    handleContactForm()
                                  }
                                />
                              )}
                            </div>
                            <p className="mt-3 text-center font-FaktumRegular text-[13px] leading-[19.5px] text-[#7D7E83]">
                              Our team will respond within 48 hours
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setEmailContactFormOpen(false)}
            className="opacity-80 fixed inset-0 z-40 bg-black"
          />
        </div>
      ) : (
        <div>
          <div className="fixed z-50 outline-none focus:outline-none">
            <div className="flex items-center justify-center w-screen h-screen px-6">
              <div className="relative w-[428px] max-h-[90%] pb-[20px] pt-[5px] rounded-lg bg-white overflow-auto outline-none focus:outline-none">
                <div className="flex items-center justify-end p-[10px]">
                  <button
                    type="button"
                    title="Close"
                    className="cursor-pointer"
                    onClick={() => setEmailContactFormOpen(false)}
                  >
                    <CancelIcon />
                  </button>
                </div>
                <div className="w-full flex items-center justify-center -mt-6">
                  <div>
                    <div className="px-[10px]">
                      <p className="text-ategbe-green mb-[px] font-FaktumBold text-[22px] -[44.8px]">
                        Contact Ategbe
                      </p>
                      <p className="text-ategbe-font-black mb-1 font-FaktumRegular text-[16px] -[6px] opacity-70">
                        To get started, fill out the form below:
                      </p>
                    </div>

                    <div>
                      <div className="mt-[20px] w-full flex items-center justify-center">
                        <form className="w-full">
                          <Inputs
                            specificInputLabel="fullName"
                            specificInputTitle="Full Name"
                            specificInputType="text"
                            specificInputPlaceholder="E.g John Doe"
                            specificInputValue={name}
                            specificInputOnChange={(e) =>
                              setName(e.target.value)
                            }
                            specificInputStyling="mb-[24px] rounded-[4px] w-full h-[42px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                          />
                          <Inputs
                            specificInputLabel="emailAddress"
                            specificInputTitle="Email address"
                            specificInputType="text"
                            specificInputPlaceholder="E.g johndoe@contact.com"
                            specificInputValue={email}
                            specificInputOnChange={(e) =>
                              setEmail(e.target.value)
                            }
                            specificInputStyling="mb-[24px] rounded-[4px] w-full h-[42px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                          />
                          <Inputs
                            specificInputLabel="mobileNumber"
                            specificInputTitle="Mobile number"
                            specificInputType="text"
                            specificInputPlaceholder="E.g +08100000000"
                            specificInputValue={phoneNumber}
                            specificInputOnChange={(e) =>
                              setPhoneNumber(e.target.value)
                            }
                            specificInputStyling="mb-[24px] rounded-[4px] w-full h-[42px] bg-white text-[#7d7e83] mb-6 placeholder-[#B9B9B9] border border-[#BBBBBB]"
                          />
                          <div>
                            <label htmlFor="message">
                              <p className="mb-1 font-FaktumBold text-[13px] leading-[19.5px] text-ategbe-font-black">
                                Message
                              </p>
                              <textarea
                                id="message"
                                type="text"
                                placeholder="Enter your message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className=" text-[14px] leading-[22px] w-full h-[80px] max-h-[150px] py-3 px-5 appearance-none focus:outline-none mb-[24px] rounded-[4px] bg-white text-[#7d7e83] placeholder-[#B9B9B9] border border-[#BBBBBB]"
                              />
                            </label>
                          </div>
                          {warning ? displayWarningMobile() : <span />}
                          <div className="flex items-center justify-center">
                            {btnIsLoading ? (
                              <Buttons
                                specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                                specificButtonTitle="Loading"
                                specificButtonIcon={
                                  <LoadingIcon className="spinning" />
                                }
                              />
                            ) : (
                              <Buttons
                                specificButtonType
                                specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                                specificButtonTitle="Send to Ategbe"
                                specificButtonText="Send to Ategbe"
                                specificButtonClick={() => handleContactForm()}
                              />
                            )}
                          </div>
                          <p className=" text-center font-FaktumRegular text-[9px] leading-[23.18px] text-[#7D7E83]">
                            Our team will respond within 48 hours
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setEmailContactFormOpen(false)}
            className="opacity-80 fixed inset-0 z-40 bg-black"
          />
        </div>
      )}
    </div>
  );
}

export default EmailContactForm;
